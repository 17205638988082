<template>
  <div>
    <router-link class="come-back" to="/admin/users/">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование пользователя' : 'Создание пользователя' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="creteUser" ref="createUser">
      <div>
        <div class="content-sb">
          <p>Имя<span>*</span></p>
          <input required placeholder="Введите имя" type="text" name="firstName" v-model="form.firstName" class="input blue">
        </div>

        <div class="content-sb">
          <p>Фамилия<span>*</span></p>
          <input required placeholder="Введите имя" type="text" name="lastName" v-model="form.lastName" class="input blue">
        </div>

        <div v-if="$route.query.type === 'create'" class="content-sb">
          <p>Пароль<span>*</span></p>
          <input required placeholder="Введите пароль" type="password" name="password" v-model="form.password" class="input blue">
        </div>

        <div class="content-sb">
          <p>Отчество</p>
          <input placeholder="Отчество" type="text" v-model="form.patronymic" class="input blue">
        </div>

        <div class="content-sb">
          <p>Email<span>*</span></p>
          <input required placeholder="Введите почту" type="email" name="email" v-model="form.email" class="input blue">
        </div>

        <div v-if="$route.query.type === 'edit'" class="content-sb">
          <p>Телефон</p>
          <input  placeholder="89998887766" type="number" name="phone" v-model="form.phone" class="input blue">
        </div>

        <div class="content-sb">
          <p>Роль<span>*</span></p>
          <select required class="input blue" ref="changeRole" @change="changeRole"  name="role">
            <option v-if="this.$route.query.type === 'create'" disabled selected>Выберите Роль</option>
            <option v-for="item in getRole" :selected="form.role === item.title" :key="`getRole${item.id}`" :value="item.code">{{ item.title }}</option>
          </select>
        </div>

        <div class="content-sb">
          <p>Статус<span>*</span></p>
          <select required class="input blue" ref="changeStatus" @change="changeStatus" name="status">
            <option v-if="this.$route.query.type === 'create'" disabled selected>Выберите Статус</option>
            <option v-for="item in getStatus" :selected="form.status === item.title" :key="`getRole${item.id}`" :value="item.code">{{ item.title }}</option>
          </select>
        </div>

        <div class="content-sb" v-if="isUserExpert">
          <p>Номинация</p>
          <select required class="input blue" ref="changeNomination" @change="changeNomination" name="nomination_id">
            <option :value="null">-</option>
            <option v-for="item in getNomination" :selected="form.nomination_id === item.id" :key="`getNomination${item.id}`" :value="item.id">{{ item.title }}</option>
          </select>
        </div>

        <div class="content-sb" v-if="isUserExpert">
          <p>Макс. количество заявок</p>
          <input type="number" name="max_appeal_count" v-model="form.max_appeal_count" class="input blue">
        </div>

        <div v-if="statusMemberId" class="content-sb">
          <p>Член ОПРФ<span>*</span></p>
          <select required class="input blue" ref="changeMemberId" @change="changeMemberId" name="memberId">
            <option v-if="this.$route.query.type === 'create'" disabled selected>Выберите Члена опрф</option>
            <option v-for="item in getMemberId" :selected="form.member === item.id" :key="`getMemberId${item.id}`" :value="item.id">{{ item.fullName }}</option>
          </select>
        </div>

      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/users" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" :value="$route.query.type === 'edit' ? 'Изменить' : 'Сохранить'"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'createUser',
  data () {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        status: '',
        role: '',
        patronymic: '',
        phone: null,
        memberId: null,
        nomination_id: null,
        max_appeal_count: 3
      },
      statusMemberId: false
    }
  },
  methods: {
    creteUser () {
      const formData = new FormData(this.$refs.createUser)
      if (this.$route.query.type === 'edit') {
        this.form.status = this.$refs.changeStatus.value
        this.form.role = this.$refs.changeRole.value
        if (this.patronymic) {
          this.form.memberId = this.$refs.changeMemberId.value
          formData.append('patronymic', this.form.patronymic)
        }
        if (this.statusMemberId) {
          this.form.memberId = this.$refs.changeMemberId.value
        }
        this.$store.dispatch('editUser', this.form)
      } else {
        this.$store.dispatch('createUser', formData)
      }
    },
    changeStatus (e) {
      this.form.status = e.target.value
    },
    changeNomination (e) {
      this.form.nomination_id = e.target.value
    },
    changeRole (e) {
      this.form.role = e.target.value
    },
    changeMemberId (e) {
      this.form.memberId = e.target.value
    }
  },
  computed: {
    getRole () {
      return this.$store.getters.getRole
    },
    getStatus () {
      return this.$store.getters.getStatus
    },
    getDetailUser () {
      return this.$store.getters.getDetailUser
    },
    getMemberId () {
      return this.$store.getters.getMemberId
    },
    getNomination () {
      return this.$store.getters.getNomination
    },
    isUserExpert () {
      return this.form.role === 'EXPERT' || this.form.role === 'Эксперт конкурса'
    }
  },
  watch: {
    'form.role' (value) {
      if (value === 'MEMBER' || value === 'Член ОПРФ') {
        this.statusMemberId = true
      } else {
        this.statusMemberId = false
        this.form.memberId = null
      }
    }
  },
  mounted () {
    this.$store.dispatch('setRole')
    this.$store.dispatch('setStatus')
    this.$store.dispatch('setMemberId')
    this.$store.dispatch('setNomination')
    if (this.$route.query.type === 'edit' && this.getDetailUser === null) {
      this.$router.push('/admin/users')
    }
    if (this.$route.query.type === 'edit') {
      this.form = this.getDetailUser
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
